import { isTrackingEnabled, isGoogleAnalyticsEnabled, isBrowser } from '../../../config/config';

interface EventFields extends Record<string, any> {
    event_category?: string;
    event_label?: string;
    value?: string | number | Record<string, any>;
    event_action?: string;
}

/**
 * Fire a google gtm event: https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
const fireGoogleAnalyticsEvent = (eventFields: EventFields = {}) => {
    if (isTrackingEnabled() === false || isGoogleAnalyticsEnabled() === false || isBrowser() === false) {
        return;
    }

    if (!window.dataLayer) {
        window.dataLayer = [];
    }

    const eventObject = {
        event_action: 'click',
        ...eventFields,
    };

    window.dataLayer.push({
        event: 'tip_datalayer_event',
        eventDetails: {
            ...eventObject,
        },
    });
};

export default fireGoogleAnalyticsEvent;
